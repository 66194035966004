import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

// Hooks & Querys & Context
import { useSiteState } from '../lib/siteContext'
import { QueryFragments } from '../GraphQl/queryFragments' // eslint-disable-line

// Components
import Layout from '../components/layout'
import Seo from '../components/global/seo'
import ModuleZone from '~moduleZone'
import { Logo } from '../components/global/logo'
import { GatsbyImage } from 'gatsby-plugin-image'

const ClientPageTemplate = ({ data: { page } }) => {
  const { pageModules, seo, title, logo } = page || {}

  const {
    setPageFeatureColor,
    setFooterHidden,
    setPageBackground,
    setMyPorter,
    setHeaderDark,
    setShowingBriefUs,
    setFooterTheme,
    setFooterForms,
  } = useSiteState()

  useEffect(() => {
    setHeaderDark(true)
    setFooterForms(false)
    setFooterTheme('theme-none')
    setMyPorter(false)
    setPageFeatureColor(null)
    setShowingBriefUs(false)
    setFooterHidden(false)
  }, [
    setFooterHidden,
    setPageBackground,
    setMyPorter,
    setHeaderDark,
    setPageFeatureColor,
    setShowingBriefUs,
    setFooterTheme,
    setFooterForms,
  ])

  return (
    <Layout>
      <Seo {...seo} hidden={true} />
      {pageModules && <ModuleZone {...pageModules} />}
    </Layout>
  )
}

export default ClientPageTemplate

export const pageQuery = graphql`
  query ClientPageTemplate($slug: String!) {
    page: sanityClientPage(slug: { current: { eq: $slug } }) {
      title
      slug {
        current
      }
      seo {
        ...SEO
      }
      pageModules: modules {
        ...PageModules
      }
    }
  }
`
